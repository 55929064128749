import React from 'react'
import Header from '../common/Header'

function ContactPage() {
  return (
    <div>
        <Header />
      ContactPage</div>
  )
}

export default ContactPage
import { useState, useEffect } from 'react';
import { MENU_API, MENU_MAPI } from '../../utils/constant';

function useResMenu(resId) {
    const [resInfo, setResInfo] = useState(null);

    useEffect(() => {
        if (resId) {  // Ensure resId is available before fetching
            fetchData();
        }
    }, [resId]); // Depend on resId to refetch if it changes

    const fetchData = async () => {
        try {
            const isMobile = window.innerWidth <= 768;
            const API = isMobile ? MENU_MAPI : MENU_API;
            const response = await fetch(API(resId)); // Call API with resId
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const json = await response.json();
            setResInfo(json.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            setResInfo(null); // Handle error by setting resInfo to null
        }
    };

    return resInfo;
}

export default useResMenu;

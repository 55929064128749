import React from 'react';
import Header from '../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function AboutPage() {

  const currentYear = new Date().getFullYear(); // Get the current year
  return (
    <div>
      <Header />
      <div className="flex flex-col lg:flex-row items-center mt-6">
        <div className="lg:w-1/2 mb-4 lg:mb-0 lg:mr-6 flex justify-center">
          <img 
            src="assets/img/food-delivery.png" 
            
            alt="Food Delivery" 
            className="" 
          />
        </div>
        <div className="lg:w-1/2 text-center lg:text-left">
          <h1 className="font-bold text-2xl mb-2 font-mono">About App</h1>
          <p className="text-gray-700 mb-2 font-mono">
            This is a clone food delivery web app using Swiggy APIs. It has multiple features including:
          </p>
          <ul className="list-none text-gray-700 mb-4 font-mono text-center mx-auto ml-10">
            <li className="flex items-center mb-2">
              <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2" />
              Searching for locations using GPS
            </li>
            <li className="flex items-center mb-2">
              <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2" />
              Listing of restaurants and menus
            </li>
            <li className="flex items-center">
              <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2" />
              Add to cart feature
            </li>
          </ul>
          <p className='font-mono'>
            Experience an amazing UI developed with React and styled using Tailwind CSS!
          </p>
        </div>
      </div>
      <div className="text-center py-2">
                <p className="text-gray-600">
                    &copy; {currentYear} Developed by <Link to="https://tarunkumaryandra.github.io/portfolio" className="text-orange-500  mt-10" target="_blank" rel="noopener noreferrer">Tarun Kumar Yandra</Link>
                </p>
            </div>
    </div>
  );
}

export default AboutPage;

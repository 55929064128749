import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter, faRedo } from '@fortawesome/free-solid-svg-icons';

function FilterButtons({ searchText, setSearchText, searchRestaurants, resetSearch, filterTopRatedRestaurants }) {
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  return (
    <div>
      <div className='filter flex flex-col lg:flex-row items-center mb-2 justify-between'>
        <div className='relative w-80 lg:w-full max-w-xl bg-white rounded-full flex mb-2'>
          <input
            placeholder="Search for restaurants"
            className="rounded-full w-full h-16 bg-transparent py-2 pl-8 outline-none border-2 border-gray-100 shadow-md hover:outline-none focus:ring-white-200 focus:border-white-200"
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button
            type="submit"
            className="absolute inline-flex items-center h-10 px-4 mr-5 lg:mr-0 py-2 text-sm  transition duration-150 ease-in-out rounded-full outline-none right-3 top-3 bg-[#f77d00] hover:bg-[#f77d00] sm:px-6 sm:text-base sm:font-medium focus:outline-none focus:ring-white-500 focus:border-white-200"
            onClick={() => searchRestaurants(searchText)}
          >
             <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-5 h-5 bg-orange-500 text-cyan-50">
                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
              </svg>
          </button>

          {/* Filter Icon */}
          <button 
            className="ml-2 lg:hidden" 
            onClick={toggleFilters}
          >
            <FontAwesomeIcon icon={faFilter} className='text-orange-500' /> {/* Font Awesome Filter Icon */}
          </button>
        </div>

        {/* Always visible on lg and above */}
        <div className='hidden lg:flex lg:items-center'>
          <button className='filter-btn bg-[#f77d00] rounded-full lg:ml-auto hover:bg-[#f77d00] text-white px-4 py-2 font-semibold' onClick={filterTopRatedRestaurants}>
            Top Rated Restaurants
          </button>
          <button className='text-gray-600 hover:text-green-600 font-bold px-2 py-2 rounded' onClick={resetSearch}>
            <FontAwesomeIcon icon={faRedo} /> {/* Font Awesome Reset Icon */}
          </button>
        </div>

        {/* Show filters when toggled on smaller screens */}
        {showFilters && (
          <div className='flex lg:hidden'>
            <button className='filter-btn bg-[#f77d00] rounded-full lg:ml-auto hover:bg-[#f77d00] text-white px-4 py-2 font-semibold' onClick={filterTopRatedRestaurants}>
              Top Rated Restaurants
            </button>
            <button className='text-gray-600 hover:text-green-600 font-bold px-2 py-2 rounded' onClick={resetSearch}>
              <FontAwesomeIcon icon={faRedo} /> {/* Font Awesome Reset Icon */}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterButtons;

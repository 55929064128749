// // const CORSPROXY = '';

// // https://proxy.cors.sh/

// export const CDN_URL =
// "https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_660/";


// export const MENU_API =
// `https://www.swiggy.com/dapi/menu/pl?page-type=REGULAR_MENU&complete-menu=true&lat=17.7635223&lng=83.3081172&restaurantId=`;


// export const MENU_MAPI =
// `https://www.swiggy.com/mapi/menu/pl?page-type=REGULAR_MENU&complete-menu=true&lat=17.7635223&lng=83.3081172&restaurantId=`;



// export const SWIGGY_MAPI = (latitude, longitude) => {
//     return `https://www.swiggy.com/dapi/restaurants/list/v5?lat=${latitude}&lng=${longitude}&is-seo-homepage-enabled=true&page_type=DESKTOP_WEB_LISTING`;
// };
  
// export const SWIGGY_API = (latitude, longitude) => {
//     return `https://www.swiggy.com/dapi/restaurants/list/v5?lat=${latitude}&lng=${longitude}&is-seo-homepage-enabled=true&page_type=DESKTOP_WEB_LISTING`;
// };

// export const GET_ADDRESS = async (latitude, longitude) => {
//   try {
//     const response = await fetch(`https://www.swiggy.com/dapi/misc/address-recommend?latlng=${latitude}%2c${longitude}`);
//     if (!response.ok) {
//       throw new Error('Failed to fetch data');
//     }
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error('Error:', error);
//     return null;
//   }
// };

  


// export const LOCATION_API = `https://www.swiggy.com/dapi/misc/place-autocomplete?input=`;

// export const ADDRESS_API = `https://www.swiggy.com/dapi/misc/address-recommend?place_id=`;

// Assuming your backend server runs on http://localhost:3000
const SERVER_URL = 'https://firefood-server.onrender.com/api';

export const CDN_URL =
  "https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_660/";

export const MENU_API = (restaurantId) => {
  return `${SERVER_URL}/menu?lat=17.7635223&lng=83.3081172&restaurantId=${restaurantId}`;
};

export const MENU_MAPI = (restaurantId) => {
  return `${SERVER_URL}/menu?lat=17.7635223&lng=83.3081172&restaurantId=${restaurantId}`;
};

export const SWIGGY_MAPI = (latitude, longitude) => {
  return `${SERVER_URL}/restaurants?lat=${latitude}&lng=${longitude}`;
};

export const SWIGGY_API = (latitude, longitude) => {
  return `${SERVER_URL}/restaurants?lat=${latitude}&lng=${longitude}`;
};

export const GET_ADDRESS = async (latitude, longitude) => {
  try {
    const response = await fetch(`${SERVER_URL}/address?lat=${latitude}&lng=${longitude}`);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

export const LOCATION_API = `${SERVER_URL}/place-autocomplete?input=`;

export const ADDRESS_API = `${SERVER_URL}/address-by-place?place_id=`;










export const LOGO_URL=
"assets/img/logo1.png"; 

export const Bike=
"assets/img/bike.gif"; 


 export const veg =
 "assets/img/v.png";


 export const nonveg =
 "assets/img/nv.png";


 export const food =
 "assets/img/food.jpg";


 export const bg=
 "assets/img/bg.png";


 export const error404 =
 "assets/img/404.gif";


 export const GPSICON=
 "assets/img/GPS.png"


 export const SEARCH=
 "assets/img/Search.png"